
@import "../../bootstrap/variables.less";

@blue-theme:				#00b2d9;
@body-bg:					#000;

@text-color:				#fff;
@link-color:				@blue-theme;
@link-hover-color:			#fff;
@link-hover-decoration:		underline;

@font-family-base:			Arial, Helvetica, sans-serif;
@font-size-base:			14px;
@line-height-base:			1;

@font-size-h1:				50px;
@headings-small-color:		#fff;

@thumb-width:				354px;
@thumb-height:				200px;
@thumb-margin-top:			2px;
@thumb-margin-right:		3px;
@thumb-margin-bottom:		2px;
@thumb-margin-left:			3px;
@thumb-border-width:		1px;
@thumb-border:				1px solid #fff;
@thumb-hover-border:		1px dashed @blue-theme;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				@blue-theme;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/popup.less";
@import "common/mozaique_simple.less";

h1 {
	margin: 5px 0;
	text-align: center;
}
a {
	text-decoration: underline;
}
.text-blue {
	color: @blue-theme;
}
#language-switcher {
	float: right;
	margin: 0 10px;
}
#social-links {
	clear: both;
	margin: 30px 0;
	text-align: center;
	overflow: hidden;
	div {
		display: inline-block;
		vertical-align: middle;
	}
}

@media(max-width: 480px) {
	h1 {
		font-size: 40px;
		small {
			font-size: 24px;
		}
	}
}

@media(max-width: 359px) {
	h1 {
		font-size: 34px;
		small {
			font-size: 20px;
		}
	}
}
